import React from 'react';
import { EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Button,Select } from 'antd';
import NetUtil from "../../Utils/NetUtil";
import StorageValue from '../../Utils/StorageValue';
import md5 from "md5"
const { Option } = Select;

export default class AddUserView extends React.Component {
    constructor() {
        super();
        this.state = {
            userId: "",
            nickname:"",
            pwd: "",
            secret:"",
            type: 3,
            ips: "",
        }
    }

    setUserId(value){
        this.setState({
            userId: value
        })
    }

    setNickName(value){
        this.setState({
            nickname: value
        })
    }

    setPwd(value) {
        this.setState({
            pwd: value
        })
    }

    setType(value) {
        this.setState({
            type: value
        })
    }

    setIps(value) {
        this.setState({
            ips: value
        })
    }
    setSecret(value){
        this.setState({
            secret: value
        })
    }


    handleChangePass() {
        if (this.state.userId == null) {
            message.error("账号不能为空！");
            return;
        }

        if (this.state.pwd == null) {
            message.error("密码不能为空！");
            return;
        }

        let tm = Math.floor(new Date().valueOf()/1000)
        NetUtil.Post(NetUtil.BaseUrl + "addUser",
            {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(this.state.userId+this.state.ips+this.state.nickname+this.state.pwd+this.state.secret+tm+this.state.type+StorageValue.userName+StorageValue.Token),
                add: this.state.userId,
                pwd: this.state.pwd,
                secret: this.state.secret,
                nickname:this.state.nickname,
                type: Number(this.state.type),
                ips:this.state.ips
            },
            (msg) => {
                let result = msg.result
                if (result != 0) {
                    message.error(msg.msg);
                    return;
                }

                message.error("添加成功");
                this.setState({
                    idCard: "",
                    deviceId: ""
                })
            },
            (err) => {
                message.error(err);
                return;
            })
    }

    render() {
        const formItemStle = {
            labelCol: { span: 4 },
            wrapperCol: { span: 18 },
        }

        return (
            <div>
                <Form style={{ textAlign: "center" }}>
                    <h1 style={{ fontSize: "30px" }}>添加新用户</h1>
                    <Form.Item label='账号:'>
                        <Input
                            placeholder="玩家账号..."
                            // style={{ width: '50%' }}
                            prefix={<UserOutlined />}
                            value={this.state.userId}
                            onChange={(e) => this.setUserId(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='昵称:'>
                        <Input
                            placeholder="玩家显示昵称..."
                            // style={{ width: '50%' }}
                            prefix={<UserOutlined />}
                            value={this.state.nickname}
                            onChange={(e) => this.setNickName(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='密码:'>
                        <Input
                            // style={{ width: '50%' }}
                            placeholder="密码..."
                            prefix={<EyeOutlined />}
                            value={this.state.pwd}
                            onChange={(e) => this.setPwd(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='密钥:'>
                        <Input
                            // style={{ width: '50%' }}
                            placeholder="密钥，不填自动生成..."
                            prefix={<EyeOutlined />}
                            value={this.state.secret}
                            onChange={(e) => this.setSecret(e.target.value)}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item label='ip白名单:'>
                        <Input
                            // style={{ width: '50%' }}
                            placeholder="ip白名单，空为不启用，多个英文逗号隔开"
                            prefix={<EyeOutlined />}
                            value={this.state.ips}
                            onChange={(e) => this.setIps(e.target.value)}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item label='账号类型:' {...formItemStle}>
                        <Select
                            defaultValue='3'
                            size={"large"}
                            onSelect={(key) => { this.setType(key) }}
                        >
                            <Option value="3">第三方</Option>
                            <Option value="2">自己</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" 
                            onClick={() => { this.handleChangePass() }}
                        >
                            确定
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}