import React from 'react';
import '@ant-design/compatible/assets/index.css';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from "../../Utils/NetUtil";
import TimeUtil from '../../Utils/TimeUtil';
import CommDialog from '../../Utils/CommDialog';
import { ColumnProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import md5 from "md5"
import { Input, message, Button, Divider, Table, Select, Form } from 'antd';
const { Option } = Select;


interface IProps {

}

interface IState {
    LogsList: Array<any>
    uid: string
    begin: number
    ranges: Array<number>
}


export default class ListView extends React.Component<IProps, IState> {
    private nums = 1000;
    private types = new Map([
        [1, "设备号"],
        [2, "证件号"],
        [3, "手机号"]
    ]); 
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
            uid: "",
            begin: 0,
            ranges: new Array<number>() 
        }
    }

    //render渲染之前执行
    componentWillMount() {
        this.NetGetCount();
    }

    // 获取总数
    private NetGetCount() {
        let tm = Math.floor(new Date().valueOf() / 1000)

        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(tm + StorageValue.userName + StorageValue.Token)
        };

        NetUtil.Post(NetUtil.BaseUrl + "count", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                let ranges = new Array<number>() 
                for(let i = 0; i < msg.data.count;i+=this.nums){
                    ranges.push(i)
                }
                this.setState({
                    ranges: ranges
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    OnSelectClick() {
        let tm = Math.floor(new Date().valueOf() / 1000)

        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(this.state.begin + this.state.uid + this.nums + tm + StorageValue.userName + StorageValue.Token),
            begin:this.state.begin,
            num:this.nums,
            id:this.state.uid
        };

        NetUtil.Post(NetUtil.BaseUrl + "info", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                let data: Array<any> = [];
                if (msg.data.list){
                    for (let index = 0; index < msg.data.list.length; index++) {
                        const element = msg.data.list[index];
                        data.push(element)
                    }
                }
                
                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })

            },
            (err: any) => {
                throw (err)
            })
    }

    SetUid(value: string) {
        this.setState({
            uid: value
        })
    }

    SetBegin(value: number) {
        this.setState({
            begin: value
        })
    }

    private Delete(record:any){
        CommDialog.showConfirm("删除确认","是否删除"+this.types.get(record.type)+"为 " + record.id,()=>{
        let tm = Math.floor(new Date().valueOf() / 1000)

        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(tm + record.uid +StorageValue.userName + StorageValue.Token),
            uid: record.uid
        };

        NetUtil.Post(NetUtil.BaseUrl + "delete", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                message.info("删除成功！");
                this.OnSelectClick();
            },
            (err: any) => {
                throw (err)
            })
        })
    }

    render() {
        this.columns = [
            {
                align: "center", title: "标志时间", dataIndex: "date", key: "date", width: "200px", fixed: 'left', render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text*1000, 'yyyy-MM-dd hh:mm:ss')}</div>
                }

            },
            { 
                align: "center", title: "参数类型", dataIndex: "type", key: "type", render: (text, record) => {
                    return <div>{this.types.get(text)}</div>
                }
            },
            { align: "center", title: "参数值", dataIndex: "id", key: "id" },
            { align: "center", title: "标签", dataIndex: "tag", key: "tag" },
            { align: "center", title: "添加厂商", dataIndex: "name", key: "name" },
            {
                align: "center", title: "操作", dataIndex: "operation", key: "operation", render:
                (text, record) => {
                    if (record.name == StorageValue.nickName) {
                        return <div>
                            <a href="javascript:;" onClick={() => this.Delete(record)} >删除</a>
                        </div>
                    }else {
                        return <div>
                        </div>
                    }
                }
            },
        ]

        return (
            <div>
                <Form layout='inline'>
                    <Form.Item label='查询参数值:'>
                        <Input style={{ width: '280px' }}
                            placeholder="输入要查询的参数值，支持模糊匹配"
                            value={this.state.uid}
                            onChange={(e) => this.SetUid(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item label='范围' >
                        <Select
                            style={{ width: "200px" }}
                            defaultValue={0}
                            onChange={(value: number) => { this.SetBegin(value) }}
                        >
                            {this.state.ranges.map((item: any) => {
                                return (
                                    <Option key={item} value={item}>{item + "-"+(item + this.nums-1)}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <Button
                            icon={<SearchOutlined />}
                            type="primary"
                            onClick={() => { this.OnSelectClick() }}
                        >
                            查询
                        </Button>
                    </Form.Item>
                </Form>
                <Divider type='horizontal' />
                <Table
                    pagination={{pageSize:100}}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />

            </div >
        );
    }
}