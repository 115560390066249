import React from 'react';
import '@ant-design/compatible/assets/index.css';

interface IProps {

}

interface IState {
}

/**
 * 在线人数
 *Created by liangzai on 19/09/12.
 */
export default class VerifyView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (
            <iframe style={{border:0,width:"100%",height:3000,}} src={window.location.pathname + "doc.html"}/>
        );
    }
}