import React from 'react';
import '@ant-design/compatible/assets/index.css';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from "../../Utils/NetUtil";
import md5 from "md5"
import { PlusOutlined } from '@ant-design/icons';
import { Input, message, Button, Divider, Typography, Upload, Select, Space, Form } from 'antd';
import Util from '../../Utils/Util';
import CommDialog from '../../Utils/CommDialog';

const { Title } = Typography;


interface IProps {

}

interface IState {
    idCard: string
    deviceId: string
    phone: string
    tag: string
    updata: any
    file: string
    tags: Array<number>
    add: string,
}


export default class UpdateView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            idCard: "",
            deviceId: "",
            phone: "",
            tag: "",
            updata: null,
            file: "",
            tags: new Array<number>(),
            add: ""
        }
    }

    options: Array<any> = new Array;

    //render渲染之前执行
    componentWillMount() {
        this.NetGetTags();
    }

    // 获取总数
    private NetGetTags() {
        let tm = Math.floor(new Date().valueOf() / 1000)

        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(tm + StorageValue.userName + StorageValue.Token)
        };

        NetUtil.Post(NetUtil.BaseUrl + "tags", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                this.setTags(msg.data.list)
            },
            (err: any) => {
                throw (err)
            })
    }

    onBtnUpdateClick(status: number) {
        let tm = Math.floor(new Date().valueOf() / 1000)
        NetUtil.Post(NetUtil.BaseUrl + "update",
            {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(this.state.deviceId + this.state.idCard + this.state.phone + status + this.state.tag + tm + StorageValue.userName + StorageValue.Token),
                idCard: this.state.idCard,
                deviceId: this.state.deviceId,
                phone: this.state.phone,
                tag: this.state.tag,
                status: status
            },
            (msg: any) => {
                this.setState({
                    idCard: "",
                    deviceId: "",
                    phone: "",
                    tag: ""
                })

                let tips = "提交：" + msg.data.total + "个，成功：" + msg.data.success + "个，失败：" + msg.data.fail + "个。"
                if (msg.data.failId) {
                    tips += "\r\n失败id为："
                    for (let index = 0; index < msg.data.failId.length; index++) {
                        const id = msg.data.failId[index];
                        tips += "\r\n" + id
                    }
                }

                if (msg.result == 0) {
                    message.info(tips)
                } else {
                    message.error(tips)
                }
            },
            (err: any) => {
                message.error(err);
                return;
            })
    }

    setIdCard(value: string) {
        this.setState({
            idCard: value
        })
    }

    setDeviceId(value: string) {
        this.setState({
            deviceId: value
        })
    }
    setPhone(value: string) {
        this.setState({
            phone: value
        })
    }
    setAdd(value: string) {
        this.setState({
            add: value
        })
    }

    setTag(value: string) {
        this.setState({
            tag: value
        })
    }

    setTags(value: any) {
        this.setState({
            tags: value
        })
    }

    private handleUpload() {
        let data = Util.ArrayBufferToBase64(this.state.updata);
        let file = this.state.file;
        let tm = Math.floor(new Date().valueOf() / 1000)
        NetUtil.Post(NetUtil.BaseUrl + "upload",
            {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(data + file + tm + StorageValue.userName + StorageValue.Token),
                file: file,
                data: data
            },
            (msg: any) => {
                let tips = "提交：" + msg.data.total + "个，成功：" + msg.data.success + "个，失败：" + msg.data.fail + "个。"
                if (msg.data.failId) {
                    tips += "\r\n失败id为："
                    for (let index = 0; index < msg.data.failId.length; index++) {
                        const id = msg.data.failId[index];
                        tips += "\r\n" + id
                    }
                }

                CommDialog.showConfirm("上传反馈", tips);
            },
            (err: any) => {
                return;
            })
    }

    private onNameChange(value: string) {
        this.setAdd(value);
    };

    private addItem() {
        this.setTags([...this.state.tags, this.state.add || `New item`]);
        this.setAdd("")
        this.setTag(this.state.add)
    };

    render() {
        return (
            <div>
                <Title level={4}>提交黑名单信息：</Title>
                <Form.Item label='证件号:'>
                    <Input
                        placeholder="输入证件号,多个时以英文逗号分割"
                        // style={{ width: '50%' }}
                        //prefix={<UserOutlined />}
                        value={this.state.idCard}
                        onChange={(e) => this.setIdCard(e.target.value)}
                        size="large"
                    />
                </Form.Item>
                <Form.Item label='设备号:'>
                    <Input
                        placeholder="输入设备号,多个时以英文逗号分割"
                        // style={{ width: '50%' }}
                        //prefix={<UserOutlined />}
                        value={this.state.deviceId}
                        onChange={(e) => this.setDeviceId(e.target.value)}
                        size="large"
                    />
                </Form.Item>
                <Form.Item label='手机号:'>
                    <Input
                        placeholder="输入手机号,多个时以英文逗号分割"
                        // style={{ width: '50%' }}
                        //prefix={<UserOutlined />}
                        value={this.state.phone}
                        onChange={(e) => this.setDeviceId(e.target.value)}
                        size="large"
                    />
                </Form.Item>

                <Form.Item label='标签:'>
                    <Select
                        style={{ width: '100%' }}
                        onChange={(value: string) => { this.setTag(value) }}
                        placeholder="点击选择或输入标签"
                        dropdownRender={(menu: any) => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <Input
                                        placeholder="输入新增的标签"
                                        //ref={inputRef}
                                        value={this.state.add}
                                        onChange={(e)=>this.onNameChange(e.target.value)}
                                    />
                                    <Button type="text" icon={<PlusOutlined />} onClick={()=>this.addItem()}>
                                        点击新增
                                    </Button>
                                </Space>
                            </>
                        )}
                        options={this.state.tags.map((item) => ({ label: item, value: item }))}
                    />
                </Form.Item>

                <Button type="primary" onClick={() => { this.onBtnUpdateClick(1) }} >
                    提交黑名单
                    </Button>
                <Divider type='vertical' />
                <Button type="primary" onClick={() => { this.onBtnUpdateClick(2) }} >
                    提交白名单
                    </Button>

                <Divider type='horizontal' />
                <Upload
                    showUploadList={false}
                    multiple={false}
                    beforeUpload={(file, FileList) => {
                        let data = new FileReader();
                        data.readAsArrayBuffer(file);
                        data.onload = () => {
                            this.setState({
                                updata: data.result,
                                file: file.name,
                            })
                        }
                        return true
                    }}
                    onChange={(info) => {
                        console.log(info)
                        if (info.file.status === 'error' || info.file.status === 'done') {
                            this.handleUpload()
                        }
                    }}
                >
                    <Button type="primary" onClick={() => {
                    }} >
                        上传黑名单
                            </Button>
                </Upload  >
                <iframe style={{ border: 0, width: "100%", height: 1200, }} src="/upload.html" />

            </div >
        );
    }
}