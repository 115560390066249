import { isNullOrUndefined } from "util";

export default class StorageValue {

    public static _userNmae: string = '';
    public static get userName(): string {
        if (this._userNmae == '')
            this._userNmae = LocalStorage.get('userName')
        return this._userNmae;
    }
    public static set userName(value) {
        this._userNmae = value
        LocalStorage.put('userName', this._userNmae)
    }

    public static _nickNmae: string = '';
    public static get nickName(): string {
        if (this._nickNmae == '')
            this._nickNmae = LocalStorage.get('nickName')
        return this._nickNmae;
    }
    public static set nickName(value) {
        this._nickNmae = value
        LocalStorage.put('nickName', this._nickNmae)
    }

    public static _passWord: string = '';
    public static get passWord() {
        if (this._passWord == '')
            this._passWord = LocalStorage.get('passWord')
        return this._passWord;
    }
    public static set passWord(value) {
        this._passWord = value
        LocalStorage.put('passWord', this._passWord)
    }

    public static _isRemberPass: string = '';
    public static get isRemberPass() {
        if (this._isRemberPass == '')
            this._isRemberPass = LocalStorage.get('isRemberPass')
        return this._isRemberPass;
    }
    public static set isRemberPass(value) {
        this._isRemberPass = value
        LocalStorage.put('isRemberPass', this._isRemberPass)
    }

    public static _token: string = '';
    public static get Token(): string {
        if (this._token == '')
            this._token = LocalStorage.get('token')
        return this._token;
    }
    public static set Token(value) {
        this._token = value
        LocalStorage.put('token', this._token)
    }

    public static _type: number = 0;
    public static get Type(): number {
        if (this._type == 0)
            this._type = LocalStorage.get('type')
        return this._type;
    }
    public static set Type(value) {
        this._type = value
        LocalStorage.put('type', this._type)
    }
}


class LocalStorage {
    public static put(key: string, data: any) {
        if (window.localStorage == undefined) {
            return;
        }

        try {
            window.localStorage[key] = data;
        }
        catch (err) {
            console.error("LocalStorage put error,key:" + key)
        }
    };

    public static get(key: string) {
        if (window.localStorage == undefined) {
            return undefined;
        }
        try {
            return window.localStorage[key];
        }
        catch (err) {
            console.error("LocalStorage get error,key:" + key)
            return undefined;
        }
    };

    public static remove(key: string) {
        if (window.localStorage == undefined) {
            return;
        }
        try {
            window.localStorage.removeItem(key);
        }
        catch (err) {
            console.error("LocalStorage Remove error,key:" + key)
        }
    };

    public static clear() {
        if (window.localStorage == undefined) {
            return;
        }
        window.localStorage.clear();
    };
}