import React from 'react';
import { Route } from 'react-router-dom';
import UserSettingView from '../../components/UserSetting/UserSettingView';
import SliderBarView from '../../components/SliderBar/SliderBarView';
import styles from './Home.module.css';
import InfoView from '../Info/InfoView';
import UpdateView from '../Update/UpdateView';
import ListView from '../Update/ListView';
import WhiteView from '../Update/WhiteView';
import VerifyView from '../Verify/VerifyView';
import ChangePassView from '../Account/ChangePassView';
import AddUserView from '../Account/AddUserView';


export default class Home extends React.Component {
    constructor(props) {
        super();
        this.state = {
            collapsed: false,
        }
    }

    get sidebarWidth() {
        return this.state.collapsed ? 80 : 256
    }

    get sidebarStyle() {
        return {
            flex: '0 0 ' + this.sidebarWidth + 'px',
            width: this.sidebarWidth + 'px'
        }
    }

    setCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    render() {
        return (
            <div className="ant-layout ant-layout-has-sider">
                <div style={this.sidebarStyle} className="ant-layout-sider ant-layout-sider-dark">
                    <SliderBarView collapsed={this.state.collapsed} />
                </div>
                <div className={`${styles['content-wrapper']} ant-layout`} style={{ overflow: "hidden" }}>
                    <div className={`${styles.header} ant-layout-header`}>
                        <UserSettingView collapsed={this.state.collapsed} setCollapsed={() => this.setCollapsed()} />
                    </div>
                    <div className={`${styles.content} ant-layout-content`}>
                        {/* 实时监控 */}
                        <Route path="/home/info" component={InfoView} />
                        <Route path="/home/update" component={UpdateView} /> 
                        <Route path="/home/verify" component={VerifyView} />
                        <Route path="/home/changepwd" component={ChangePassView} />
                        <Route path="/home/list" component={ListView} />
                        <Route path="/home/white" component={WhiteView} />
                        <Route path="/home/adduser" component={AddUserView} />
                    </div>
                </div>
            </div>
        );
    }
}