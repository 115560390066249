import React from 'react';
import '@ant-design/compatible/assets/index.css';
import StorageValue from '../../Utils/StorageValue';
import NetUtil from "../../Utils/NetUtil";
import TimeUtil from '../../Utils/TimeUtil';
import { ColumnProps } from 'antd/lib/table';
import md5 from "md5"
import {  message, Typography, Divider, Table } from 'antd';
import CommDialog from '../../Utils/CommDialog';
const { Title } = Typography;

interface IProps {

}

interface IState {
    LogsList: Array<any>
}


export default class WhiteView extends React.Component<IProps, IState> {
    private types = new Map([
        [1, "设备号"],
        [2, "证件号"],
        [3, "手机号"]
    ]); 
    private columns: Array<ColumnProps<any>> = new Array<ColumnProps<any>>();
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            LogsList: [],
        }
    }

    //render渲染之前执行
    componentWillMount() {
        this.OnSelectClick();
    }


    OnSelectClick() {
        let tm = Math.floor(new Date().valueOf() / 1000)

        let postData = {
            user: StorageValue.userName,
            tm: tm,
            sign: md5(tm + StorageValue.userName + StorageValue.Token),
        };

        NetUtil.Post(NetUtil.BaseUrl + "white", postData,
            (msg: any) => {
                let Result = msg.result
                if (Result != 0) {
                    message.error(msg.msg);
                    return;
                }

                let data: Array<any> = [];

                if(msg.data.list){
                    for (let index = 0; index < msg.data.list.length; index++) {
                        const element = msg.data.list[index];
                        data.push(element)
                    } 
                }

                this.setState({
                    LogsList: data.sort(
                        (a: any, b: any) => {
                            return a.date > b.date ? -1 : 1
                        })
                })
            },
            (err: any) => {
                throw (err)
            })
    }

    private Delete(record:any){
        CommDialog.showConfirm("删除确认","是否删除"+this.types.get(record.type)+"为 " + record.id ,()=>{
            let tm = Math.floor(new Date().valueOf() / 1000)

            let postData = {
                user: StorageValue.userName,
                tm: tm,
                sign: md5(tm + record.uid +StorageValue.userName + StorageValue.Token),
                uid: record.uid
            };
    
            NetUtil.Post(NetUtil.BaseUrl + "delete", postData,
                (msg: any) => {
                    let Result = msg.result
                    if (Result != 0) {
                        message.error(msg.msg);
                        return;
                    }
    
                    message.info("删除成功！");
                    this.OnSelectClick();
                },
                (err: any) => {
                    throw (err)
                })
        });
    }

    render() {
        this.columns = [
            {
                align: "center", title: "标志时间", dataIndex: "date", key: "date", width: "200px", fixed: 'left', render: (text, record) => {
                    return <div>{TimeUtil.TimestampToTime(text*1000, 'yyyy-MM-dd hh:mm:ss')}</div>
                }

            },
            { 
                align: "center", title: "参数类型", dataIndex: "type", key: "type", render: (text, record) => {
                    return <div>{this.types.get(text)}</div>
                }
            },
            { align: "center", title: "参数值", dataIndex: "id", key: "id" },
            { align: "center", title: "标签", dataIndex: "tag", key: "tag" },
            { align: "center", title: "添加厂商", dataIndex: "name", key: "name" },
            {
                align: "center", title: "操作", dataIndex: "operation", key: "operation", render:
                    (text, record) => {
                        return <div>
                                <a href="javascript:;" onClick={() => this.Delete(record)} >删除</a>
                            </div>
                    }
            },
        ]

        return (
            <div>
                <Title level={4}>白名单只显示自己添加的，同样也只有自己添加的会起作用！！！</Title>
                <Divider type='horizontal' />
                <Table
                    pagination={{pageSize:100}}
                    size={"small"}
                    columns={this.columns}
                    bordered={true}
                    dataSource={this.state.LogsList}
                    rowKey={(record: any, index: any) => { return index.toString() }}
                />

            </div >
        );
    }
}