var base64js = require('base64-js');

export default class Util {
    //排序
    public static Sort(data: Array<any>, key: string) {
        if (key[0] == '-') {
            key = key.substr(1);
            return data.sort((a: any, b: any) => {
                return a[key] > b[key] ? -1 : 1
            })
        } else {
            return data.sort((a: any, b: any) => {
                return a[key] > b[key] ? 1 : -1
            })
        }
    }

    public static ArrayBufferToBase64(ab: any) {
        return base64js.fromByteArray(new Uint8Array(ab));
    }

    public static ArrayRemove(array: Array<any>, predicate: (value: any, index: number, obj: any[]) => boolean): boolean {
        var index = array.findIndex(predicate)
        if (index >= 0) {
            array.splice(index, 1);
            return true;
        }

        return false;
    }
}