import React from 'react';
import '@ant-design/compatible/assets/index.css';
import StorageValue from '../../Utils/StorageValue';
import {Typography, Descriptions } from 'antd';
const { Title } = Typography;

interface IProps {

}

interface IState {
}

export default class InfoView extends React.Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);
    }

    render() {
        return (
            <div>
                <Title level={4}>当前用户信息：</Title>
                < Descriptions bordered column={1} size='small'>
                        <Descriptions.Item label='账号:'>
                            {StorageValue.userName}
                        </Descriptions.Item>
                        <Descriptions.Item label='显示昵称:'>
                            {StorageValue.nickName}
                        </Descriptions.Item>
                        <Descriptions.Item label='密钥:'>
                            {StorageValue.Token}
                        </Descriptions.Item>
                </Descriptions>
            </div >
        );
    }
}